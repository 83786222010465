import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'app-task-reassigned-modal',
  templateUrl: './task-reassigned-modal.component.html',
  styleUrls: ['./task-reassigned-modal.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, TranslateModule],
})
export class TaskReassignedModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  @Input() assignedToContact: boolean;
  @Input() isNewContact: boolean;
}
