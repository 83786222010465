import { Injectable, inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

const HISTORY_MAX_LENGTH = 100;

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  private readonly router = inject(Router);
  private history = [];

  public init(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        if (this.history.length === HISTORY_MAX_LENGTH) {
          this.history.shift();
        }
        this.history = [...this.history, urlAfterRedirects];
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/index';
  }
}
