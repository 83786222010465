<div class="modal-body">
  <img [appAssetSrc]="isNewContact ? 'assets/svg/common/email-sent.svg' : 'assets/svg/common/success.svg'" />
  <h3>{{ 'taskReassignedModal.taskRessigned' | translate }}</h3>
  @if (!assignedToContact) {
    <div>
      {{ 'taskReassignedModal.assignedToDepartments' | translate }}
    </div>
  }
  @if (assignedToContact && isNewContact) {
    <div>
      {{ 'taskReassignedModal.assignedToNewContact' | translate }}
    </div>
  }
  @if (assignedToContact && !isNewContact) {
    <div>
      {{ 'taskReassignedModal.assignedToSelectedContact' | translate }}
    </div>
  }
  <button
    (click)="activeModal.close()"
    class="btn btn-primary submit-btn"
  >
    {{ 'common.buttons.ok' | translate }}
  </button>
</div>
