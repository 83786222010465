<div class="modal-body">
  <div class="reassign-task-modal_section">
    <img appAssetSrc="assets/svg/task-wizard/reassign-to-department.svg" />
    <h3>{{ 'reassignTaskModal.reassignToDepartment' | translate }}</h3>
    <div>{{ 'reassignTaskModal.reassignToDepartmentMsg' | translate }}</div>
    <button
      (click)="selectDepartments()"
      class="btn btn-primary submit-btn"
    >
      {{ 'reassignTaskModal.buttons.selectDepartment' | translate }}
    </button>
  </div>

  <div class="reassign-task-modal_separator">
    <div></div>
    <span>
      {{ 'common.strings.or' | translate }}
    </span>
    <div></div>
  </div>

  <div class="reassign-task-modal_section">
    <img appAssetSrc="assets/svg/task-wizard/reassign-to-contact.svg" />
    <h3>{{ 'reassignTaskModal.reassignToContact' | translate }}</h3>
    <div>{{ 'reassignTaskModal.reassignToContactMsg' | translate }}</div>
    <button
      (click)="selectContact()"
      class="btn btn-primary submit-btn"
    >
      {{ 'reassignTaskModal.buttons.selectContact' | translate }}
    </button>
  </div>
</div>
